<template>
  <a-row :gutter="16">
    <a-col :xs="24" :lg="24">
      <Card>
        <a-menu v-model="current" mode="horizontal">
          <a-menu-item key="user">USER INFORMATION</a-menu-item>
          <a-menu-item key="password">CHANGE PASSWORD</a-menu-item>
        </a-menu>
        <div class="detail" v-if="current[0] === 'user'">
          <a-row>
            <a-col :xs="24" :md="12" :lg="4" v-if="role === 'Teacher'">
              <h3 class="type">NIK</h3>
              <p class="value mt-2">{{ user.nik ? user.nik : "-" }}</p>
            </a-col>
            <a-col :xs="24" :md="12" :lg="6" v-else-if="role === 'Student'">
              <h3 class="type">NIS</h3>
              <p class="value mt-2">{{ user.nis ? user.nis : "-" }}</p>
            </a-col>
            <a-col :xs="24" :md="12" :lg="6">
              <h3 class="type">Nama</h3>
              <p class="value mt-2">{{ user.nama ? user.nama : "-" }}</p>
            </a-col>
            <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
              <h3 class="type">Jenis Kelamin</h3>
              <p class="value mt-2">
                {{ user.jenis_kelamin ? user.jenis_kelamin : "-" }}
              </p>
            </a-col>
            <a-col :xs="24" :md="12" :lg="6" class="mt-4 mt-lg-0">
              <h3 class="type">Email</h3>
              <div class="d-flex align-items-center mt-2">
                <p class="value mr-2">
                  {{ currentEmail ? currentEmail : "-" }}
                </p>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="detail" v-else>
          <a-row class="row-password" type="flex" justify="center">
            <a-form @submit.prevent="changePassword">
              <a-col :span="24">
                <a-form-item label="Current Password">
                  <!-- <a-input
                    v-model="currentPassword"
                    type="password"
                    style="height: 48px"
                    autocomplete="on"
                  />-->
                  <a-input-password size="large" v-model="currentPassword" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="New Password">
                  <!-- <a-input
                    v-model="newPassword"
                    type="password"
                    style="height: 48px"
                    autocomplete="on"
                  />-->
                  <a-input-password size="large" v-model="newPassword" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Confirm New Password">
                  <!-- <a-input
                    v-model="confirmNewPassword"
                    type="password"
                    style="height: 48px"
                    autocomplete="on"
                  />-->
                  <a-input-password size="large" v-model="confirmNewPassword" />
                </a-form-item>
              </a-col>
              <a-button
                class="btnChange"
                type="primary"
                html-type="submit"
                size="large"
                block
                >Change Password</a-button
              >
            </a-form>
          </a-row>
        </div>
      </Card>
    </a-col>
  </a-row>
</template>

<script>
import Card from '@/components/app/Card'
export default {
  components: {
    Card,
  },
  data() {
    return {
      current: ['user'],
      editableEmail: false,
      isVisible: false,
      alert: {
        message: 'Error',
        description: 'Error',
        type: 'error',
      },
      currentPassword: '',
      currentEmail: '',
      newPassword: '',
      confirmNewPassword: '',
      dataTahunAjaran: [],
    }
  },
  methods: {
    editEmail() {
      this.editableEmail = true
    },
    updateEmail() {
      this.editableEmail = false
      // console.log(this.user)
      const payload = {
        id: this.user.id,
        email: this.currentEmail,
        role: this.role,
      }
      this.$store.dispatch('user/UPDATE_EMAIL', { payload })
    },
    changePassword() {
      const newData = {}
      // console.log(this.newPassword.length)
      if (this.currentPassword && this.newPassword && this.confirmNewPassword) {
        if (this.newPassword.length >= 6) {
          if (this.newPassword === this.confirmNewPassword) {
            if (this.role === 'Teacher') {
              newData.tipe = this.role
              newData.username = this.user.nik
            } else if (this.role === 'Student') {
              newData.tipe = this.role
              newData.username = this.user.nis
            } else if (this.role === 'Admin') {
              newData.tipe = this.role
              newData.username = this.user.username
            }
            newData.currentPassword = this.currentPassword
            newData.newPassword = this.newPassword
            this.$store.dispatch('user/CHANGE_PASSWORD', {
              newData,
            })
              .then(res => {
                if (res === 'SUCCESS_RESET_PASSWORD') {
                  this.$notification.success({
                    message: 'Success.',
                    description: 'Succes Change Password',
                  })
                  this.currentPassword = ''
                  this.newPassword = ''
                  this.confirmNewPassword = ''
                } else if (res === 'INVALID_PASSWORD') {
                  this.$notification.error({
                    message: 'Sorry.',
                    description: 'Current password do not correct',
                  })
                  this.currentPassword = ''
                  this.newPassword = ''
                  this.confirmNewPassword = ''
                }
              })
          } else {
            this.$notification.error({
              message: 'Sorry.',
              description: 'New password do not match',
            })
          }
        } else {
          this.$notification.error({
            message: 'Error',
            description:
              'New password too short, minimum 6 characters',
          })
        }
      } else {
        this.$notification.error({
          message: 'Sorry.',
          description: 'You must fill all forms',
        })
      }
    },
  },
  mounted() {
    if (this.role === 'Student') {
      this.currentEmail = this.user.email_siswa
    } else {
      this.currentEmail = this.user.email
    }
    // console.log(this.user)
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
}
</script>

<style scoped>
.card-profile {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 40px 20px;
}
</style>
