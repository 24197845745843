<template>
  <div>
    <h1>My Account</h1>
    <Account />
  </div>
</template>

<script>
import Account from '@/components/app/Account'
export default {
  components: {
    Account,
  },
}
</script>

<style>
</style>
